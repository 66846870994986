// import $ from 'jquery';
import 'popper.js';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/scrollspy';
import Dropzone from 'dropzone';
// import 'bootstrap/dist/js/bootstrap';

(function($) {
  "use strict"; // Start of use strict

  // Smooth scrolling using jQuery easing
  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: (target.offset().top - 56)
        }, 1000, "easeInOutExpo");
        return false;
      }
    }
  });

  // Closes responsive menu when a scroll trigger link is clicked
  $('.js-scroll-trigger').click(function() {
    $('.navbar-collapse').collapse('hide');
  });

  // Activate scrollspy to add active class to navbar items on scroll
  $('body').scrollspy({
    target: '#mainNav',
    offset: 56
  });

})(jQuery); // End of use strict


function perc2bgcolor(perc,min,max) {
  var base = (max - min);

  if (base == 0) { perc = 100; }
  else {
      perc = (perc - min) / base * 100;
  }
  var r, g, b = 0;
  if (perc < 50) {
      r = 255;
      g = Math.round(5.1 * perc);
  }
  else {
      g = 255;
      r = Math.round(510 - 5.10 * perc);
  }
  const h = r * 0x10000 + g * 0x100 + b * 0x1;
  return '#' + ('000000' + h.toString(16)).slice(-6);
}

function perc2fgcolor(perc,min,max) {
  // https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
  var base = (max - min);

  if (base == 0) { perc = 100; }
  else {
      perc = (perc - min) / base * 100;
  }
  var r, g, b = 0;
  if (perc < 50) {
      r = 255;
      g = Math.round(5.1 * perc);
  }
  else {
      g = 255;
      r = Math.round(510 - 5.10 * perc);
  }
  const o = Math.round(((r * 299) + (g * 587) + (b * 114)) /1000);

  if(o > 50) {
    return 'black';
  } else {
    return 'white';
  }
}


$(document).on("turbolinks:load", function() {
  // $("div#myId").dropzone({ url: "/file/post" });
  // console.log("cioa");
  // var myDropzone = new Dropzone("div#myId2", { url: "/file/post"});
  Dropzone.options.uploadDropzone = {
    paramName: "filename", // The name that will be used to transfer the file
    // maxFilesize: 2, // MB
    // accept: function(file, done) {
    //   if (file.name == "justinbieber.jpg") {
    //     done("Naha, you don't.");
    //   }
    //   else { done(); }
    // }
  };

  $('.expand-list').on('click', function() {
    const target = $(this).data('target');
    $(target+" tr").removeClass('d-none');
    $(target+" .exp-btn").addClass('d-none');
  })

  $('.colored-row').each(function(){
    $(this).find('')
    const minVal = $(this).data('min-val');
    const maxVal = $(this).data('max-val');
    $(this).find('.colored-cell').each(function() {
      const v = $(this).data('valuez');
      const bgColor = perc2bgcolor(parseInt(v), parseInt(minVal), parseInt(maxVal));
      const fgColor = perc2fgcolor(parseInt(v), parseInt(minVal), parseInt(maxVal));
      $(this).css("background-color", bgColor);
      $(this).css("color", fgColor);
    })
  })

});
