import "core-js/stable";
import "regenerator-runtime/runtime";
require.context('../images', true)

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("jquery")
require("bootstrap")
require("d3")
// var d3 = require('d3')

require("atterra/widths")
require("atterra/draw-d3")
require("atterra/main")
// require("atterra/drag_n_drop_file")

// require("dropzone")


import '../src/application' // OK

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import 'bootstrap/js/dist/tooltip'
// import 'bootstrap'

// questi risolvono:
// import jQuery from 'jquery';
// window.$ = window.jQuery = jQuery;


// console.log(jQuery.fn.jquery);
console.log($('[data-toggle="tooltip"]'));
$('[data-toggle="tooltip"]').tooltip();
// document.addEventListener("turbolinks:load", () => {
//   $('[data-toggle="tooltip"]').tooltip()
// })
