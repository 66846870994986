import * as d3 from "d3";
// require("atterra/widths")
// import 'atterra/widths';


function getViewportWidth() {
  if (window.innerWidth) {
    return window.innerWidth;
  } else if (document.body && document.body.offsetWidth) {
    return document.body.offsetWidth;
  } else {
    return 0;
  }
}

function getViewportHeight() {
  if (window.innerHeight) {
    return window.innerHeight;
  } else if (document.body && document.body.offsetHeight) {
    return document.body.offsetHeight;
  } else {
    return 0;
  }
}

var tellMeTheSizes = function() {
  console.log(getViewportWidth() + "px");
  console.log(getViewportHeight() + "px");
  console.log(screen.height + "px");
  console.log(screen.width + "px");
  // document.getElementById("viewportwidth").innerHTML = getViewportWidth() + "px";
  // document.getElementById("viewportheight").innerHTML = getViewportHeight() + "px";
  // document.getElementById("resolutionheight").innerHTML = screen.height + "px";
  // document.getElementById("resolutionwidth").innerHTML = screen.width + "px";
}



// an item:
// {"name":"IASAZ","category":"M","earliest_time":"2020-01-29T14:54:03.000Z",
//   "earliest_num":34008843,"best_time":"2020-01-29T15:01:00.000Z","best_num":34009260,
//   "latest_time":"2020-01-29T15:13:47.000Z","latest_num":34010027,"result":"landed",
//   "landing_time":"2020-01-29T15:01:00.000Z","landing_num":34009260,"interval_num":90}

// function drawLandingItem(landing_list) {
function drawAircraftItem(landing_list) {
  const approach = landing_list.approach;
  const items = landing_list.items;

  const earliest_times = items.map(function(el){return el.earliest_num });
  const latest_time = items.map(function(el){return el.latest_num });

  const min_num = d3.min(earliest_times);
  const max_num = d3.max(latest_time);

  const x_offset = 14;
  const strip_height = 12; // altezza della striscia
  const row_height = 24; // distanza tra le baseline delle striscie

  // {name: "ITEM_001", category: "M", earliest_time: {…}, best_time: {…}, latest_time: {…}}
  // gon.original_items[0].best_time
  //    {time: "2019-11-27T23:23:50.000+00:00", num: 28596230}
  const width = max_num-min_num+100;
  const height = items.length*row_height+30;
  // const svg_cont_width = $('.svg-container').offsetWidth;
  // const svg_cont_width = window.screen.width * 0.9;
  const svg_cont_width = getViewportWidth()-64;
  const x_factor = (svg_cont_width*0.95)/width;
  console.log("wow");

  d3.select("svg").remove();
  d3.select(".svg-container").attr("style", `height: ${height+50}px;`);

  var svgViewport = d3.select(".svg-container").append("svg").attr("width",svg_cont_width).attr("height",height).attr('class', 'very-largex');

  // striscia aereo (tra earliest_time e latest_time)
  var rectSelection = svgViewport.selectAll("rect").data(items);
  var rectElements = rectSelection.enter().append("rect");
  rectElements.attr('x', function(d, i) { return x_factor*(d.earliest_num-min_num)+x_offset; })
    .attr('y', function(d, i) { return 5+i*row_height; })
    .attr('width', function(d, i) { return x_factor*(d.latest_num-d.earliest_num); })
    .attr('height', function(d, i) { return strip_height; })
    .attr("class", function(d) { return "strip-rect "+d.category.toLowerCase() });

  // linea del best_time dentro la striscia dell'aereo
  var lineSelection = svgViewport.selectAll("line.best-time-line").data(items);
  var lineElements = lineSelection.enter().append("line");
  lineElements.attr('x1', function(d, i) { return x_factor*(d.best_num-min_num)+x_offset; })
    .attr('y1', function(d, i) { return 5+i*row_height; })
    .attr('x2', function(d, i) { return x_factor*(d.best_num-min_num)+x_offset; })
    .attr('y2', function(d, i) { return 5+i*row_height+strip_height; })
    .attr("class", "best-time-line");

  // triangolino verde o rosso del momento dell'atterraggio o holding
  if (items[0].hasOwnProperty('landing_num')) {
    var triangleSelection = svgViewport.selectAll("polygon").data(items);
    var triangleElements = triangleSelection.enter().append("polygon");
    triangleElements.attr('points', function(d, i) {
      var x1 = x_factor*(d.landing_num-min_num)+x_offset;
      var y1 = 5+i*row_height;
      var x2 = x_factor*(d.landing_num-min_num)+x_offset+(0.385*strip_height);
      var y2 = 5+i*row_height+0.667*strip_height;
      var x3 = x_factor*(d.landing_num-min_num)+x_offset-(0.385*strip_height);
      var y3 = 5+i*row_height+0.667*strip_height;
      return `${x1},${y1} ${x2},${y2} ${x3},${y3}`;
    }).attr("class", function(d){ return `landing-time-triangle ${d.result}`; });


    // striscie grigie che rappresentano gli intervalli tra un aereo e l'altro
    // in realtà viene disegnata una linea
    //
    //       +------------------+
    //    (x1,y1)            (x2,y2)
    //
    var intervalLineSelection = svgViewport.selectAll("line.interval-line").data(items);
    var intervalLineElements = intervalLineSelection.enter().append("line");
    var lastLandingNum = null;
    intervalLineElements.attr('x1', function(d, i) {
      return x_factor*(d.landing_num-min_num)+x_offset;
    })
      .attr('y1', function(d, i) { return 5+i*row_height+17; })
      .attr('x2', function(d, i) {
        return x_factor*(d.landing_num+d.interval_num-min_num)+x_offset;
    })
      .attr('y2', function(d, i) { return 5+i*row_height+17; })
      .attr("class", "interval-line");

    // etichetta valore intervallo (in secondi)
    var textSelection = svgViewport.selectAll("text.interval-text").data(items);
    var textElements = textSelection.enter().append("text");
    // textElements.attr('x', function(d, i) { return x_factor*(d.landing_num+d.interval_num-min_num)+x_offset+3; })
    textElements.attr('x', function(d, i) {
      return x_factor*(d.landing_num+d.interval_num-min_num)+x_offset+3;
      // if (d.result=='holding') {
      //   return x_factor*(lastLandingNum-d.landing_num+d.interval_num-min_num)+x_offset+3;
      // } else {
      //   return x_factor*(d.landing_num+d.interval_num-min_num)+x_offset+3;
      // }



    })
      .attr('y', function(d, i) { return 5+i*row_height+17+4; })
      .text( function(d){ return d.interval_num; })
      .attr("class", "interval-text");

  }

  // etichetta nome aereo
  var textSelection = svgViewport.selectAll("text.name-text").data(items);
  var textElements = textSelection.enter().append("text");
  textElements.attr('x', function(d, i) { return x_factor*(d.latest_num-min_num)+3+x_offset; })
    .attr('y', function(d, i) { return 5+i*row_height+strip_height-3; })
    .text( function(d){ return d.name; })
    .attr("class", "name-text");

    // etichetta categoria aereo
  var textElements = textSelection.enter().append("text");
  textElements.attr('x', function(d, i) { return x_factor*(d.earliest_num-min_num)-12+x_offset; })
    .attr('y', function(d, i) { return 5+i*row_height+strip_height-1; })
    .text( function(d){ return d.category; })
    .attr("class", "categ-text");



}




function drawLandingItems() {
  if (typeof(gon.results) != "undefined" && gon.results.length>0) {
    for (var landing_list_id in gon.results) {
      if (gon.results.hasOwnProperty(landing_list_id)) {
        drawAircraftItem(gon.results[landing_list_id]);
      }
    }
  }
}



function drawOriginalItems() {
  if (typeof(gon.aircraft_list) != "undefined") {
    console.log($('#timeLineModal .modal-body').clientWidth);
    tellMeTheSizes();
    drawAircraftItem(gon.aircraft_list);
  }
}


$(document).on("turbolinks:load", function() {

  $('#show-items-timeline').on('click', function() {
    $('#timeLineModal .modal-title').html($(this).data('approach'));
    $('#timeLineModal').modal('show');
    $('#timeLineModal').on('show.bs.modal', drawOriginalItems());
    // $('#exampleModal').on('shown.bs.modal', tellMeTheSizes());
  })

  $('.show-timeline').on('click', function() {
    const ll_id = $(this).data('landing-list-id');
    $('#timeLineModal .modal-title').html($(this).data('approach'));
    $('#timeLineModal').modal('show');
    $('#timeLineModal').on('show.bs.modal', drawAircraftItem(gon.results[ll_id]));
    // $('#exampleModal').on('shown.bs.modal', tellMeTheSizes());

    // console.log(ll_id);
  })

});
